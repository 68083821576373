import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';

import novo from '../../../assets/img/add.png';
import playlistMenu from '../../../assets/img/playlist-menu.png';
import playlistImage from '../../../assets/img/playlist.png';
import perfil from '../../../assets/img/perfil.png';
import voltar from '../../../assets/img/voltar.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import run from '../../../assets/img/run.png';
import './playlistReports.scss';

export default function PlaylistReports({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const { id_playlist } = useParams();
    const [playlistReports, setPlaylistReports] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchPlaylistReports() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getPlaylistReports`, { fingerprint: fpHash, id_playlist: id_playlist }, config);

                if (response.data.success) {
                    const playlist_reports = response.data.playlist_reports;

                    setPlaylistReports(playlist_reports);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        if (fpHash) {
            fetchPlaylistReports();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const handleOpenPlaylistRun = () => {
        if(playlistReports.length === 0) {
            swal('Erro', 'Adicione ao menos um relatório a playlist', 'error');
            return;
        }
        navigate(`/playlist/run/${id_playlist}`);
    };

    const handleEditPlaylistReport = (id) => {
        navigate(`/editarPlaylistReport/${id_playlist}/${id}`);
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                        
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='relatorios'>
                    <div className='relatorios-card'>
                        <h1>Relatórios da Playlist</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate(`/playlists`)} />
                            <img src={images.addImage} onClick={() => navigate(`/adicionarPlaylistReport/${id_playlist}`)} alt='Novo'/>
                        </div>
                        <div className='relatorios-list'>
                            {loading ? (
                                <div className="loading-spinner textReport">
                                    <ClipLoader color="#000000" loading={loading} size={50} /> 
                                </div>
                            ) : playlistReports.length > 0 ? (
                                playlistReports.map((playlistReport, index) => (
                                    <div className='group-playlist-report' onClick={() => handleEditPlaylistReport(playlistReport.id)} key={index}>
                                        <span className='infos-playlist-report'>{playlistReport.tempo_atualizacao}s / tela - {playlistReport.navegacao == 'S' ? 'Navegável' : 'Não navegável'}</span>
                                        <img className='playlist-report-img' src={uri + '/' + playlistReport.path_img}/>
                                        <span>{playlistReport.name}</span>
                                    </div>
                                ))
                            ) : (
                                <span className='textReport'>Nenhum relatório de playlist disponível</span>
                            )}
                        </div>
                        <div className='playlist-button' onClick={handleOpenPlaylistRun}>
                            <span>Iniciar Playlist</span>
                            <img className='run' src={run} />
                        </div>
                        {/* <span className='desc-playlists'>Playlists = Grupo de um ou mais relatórios na qual cada tela do relatório é percorrida automaticamente.</span> */}
                    </div>
                </div>
            </div>
        </section>
    );
}