import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import './App.scss';
import { images, texts } from './styles_sites/nexus/_variables';
import Login from './pages/login/Login';
import Relatorio from './pages/user/relatorio/Relatorio';
import Relatorios from './pages/user/relatorios/Relatorios';
import Gerenciamento from './pages/adm/gerenciamento/Gerenciamento';
import Default from './components/default/Default';
import GrupoRelatorios from './pages/adm/grupoRelatorios/GrupoRelatorios';
import Usuarios from './pages/adm/usuarios/Usuarios';
import AdicionarUsuario from './pages/adm/adicionarUsuario/AdicionarUsuario';
import EditarUsuario from './pages/adm/editarUsuario/EditarUsuario';
import AdicionarGrupo from './pages/adm/adicionarGrupo/AdicionarGrupo';
import EditarGrupo from './pages/adm/editarGrupo/EditarGrupo';
import AdicionarRelatorio from './pages/adm/adicionarRelatorio/AdicionarRelatorio';
import EditarRelatorio from './pages/adm/editarRelatorio/EditarRelatorio';
import Perfil from './pages/user/perfil/Perfil';
import PerfilAdm from './pages/adm/perfilAdm/PerfilAdm';
import Playlists from './pages/user/playlists/Playlists';
import PlaylistReports from './pages/user/playlistReports/PlaylistReports';
import PlaylistRun from './pages/user/playlistRun.jsx/PlaylistRun';
import AdicionarPlaylist from './pages/user/adicionarPlaylist/AdicionarPlaylist';
import AdicionarPlaylistReport from './pages/user/adicionarPlaylistReport/adicionarPlaylistReport';
import EditarPlaylistReport from './pages/user/editarPlaylistReport/EditarPlaylistReport';
import EditarPlaylist from './pages/user/editarPlaylist/EditarPlaylist';
import Grupos from './pages/user/grupos/Grupos';
import GrupoReports from './pages/user/grupoReports/GrupoReports';
import RelatoriosGerenciais from './pages/adm/relatoriosGerenciais/RelatoriosGerenciais';
import VisualizarRelatorio from './pages/adm/visualizarRelatorio/VisualizarRelatorio';
import Suporte from './pages/adm/suporte/Suporte';


function App() {
    const [fpHash, setFpHash] = useState('');
    const [expanded, setExpanded] = useState(false); // Estado para controlar a expansão do relatório
    const containerRef = useRef(null);

    useEffect(() => {
        const loadFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            setFpHash(visitorId);
        };
        loadFingerprint();
    }, []);

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement
            ) {
                setExpanded(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    const enterFullscreen = () => {
        if (containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
        } else if (containerRef.current.mozRequestFullScreen) { // Firefox
            containerRef.current.mozRequestFullScreen();
        } else if (containerRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
            containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.msRequestFullscreen) { // IE/Edge
            containerRef.current.msRequestFullscreen();
        }
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
        }
    };

    const toggleExpandirRelatorio = () => {
        setExpanded(!expanded); // Inverte o estado de expansão
        expanded ? exitFullscreen() : enterFullscreen(); // Sai ou entra em tela cheia
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login images={images} texts={texts} />} />
                <Route path="/relatorio/:id_report" element={<Relatorio fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/home" element={<Grupos fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/grupo/:id_grupo/:name_group" element={<GrupoReports fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/relatorios" element={<Relatorios fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/perfil" element={<Perfil fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/playlists" element={<Playlists fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/playlist/:id_playlist" element={<PlaylistReports fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/playlist/run/:id_playlist" element={<PlaylistRun fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/adicionarPlaylist" element={<AdicionarPlaylist fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/adicionarPlaylistReport/:id_playlist" element={<AdicionarPlaylistReport fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/editarPlaylistReport/:id_playlist/:id_playlist_report" element={<EditarPlaylistReport fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />
                <Route path="/editarPlaylist/:id_playlist/:name_playlist" element={<EditarPlaylist fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />

                <Route path="/gerenciamento" element={<Default lineClass="gerenciamento-line" fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><Gerenciamento /></Default>} />
                <Route path="/grupoRelatorios/:group/:name" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><GrupoRelatorios /></Default>} />
                <Route path="/usuarios" element={<Default lineClass="usuarios-line" fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><Usuarios /></Default>} />
                <Route path="/adicionarUsuario" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><AdicionarUsuario /></Default>} />
                <Route path="/adicionarGrupo" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><AdicionarGrupo /></Default>} />
                <Route path="/editarUsuario/:id_user" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><EditarUsuario /></Default>} />
                <Route path="/editarGrupo/:id_grupo/:name" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><EditarGrupo /></Default>} />
                <Route path="/adicionarRelatorio/:id_grupo/:name" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><AdicionarRelatorio /></Default>} />
                <Route path="/editarRelatorio/:id_grupo/:name/:id_relatorio" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><EditarRelatorio /></Default>} />
                <Route path="/perfilAdm" element={<Default fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><PerfilAdm /></Default>} />
                <Route path="/relatoriosGerenciais" element={<Default lineClass="relatorios-menu-line" fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><RelatoriosGerenciais /></Default>} />
                <Route path="/suporte" element={<Default lineClass="suporte-menu-line" fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} ><Suporte /></Default>} />
                <Route path="/visualizarRelatorio/:id_report" element={<VisualizarRelatorio fpHash={fpHash} expanded={expanded} expRelatorio={toggleExpandirRelatorio} containerRef={containerRef} images={images} texts={texts} />} />

            </Routes>
        </Router>
    );
}

export default App;
