import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';

import novo from '../../../assets/img/add.png';
import playlistMenu from '../../../assets/img/playlist-menu.png';
import playlistImage from '../../../assets/img/playlist.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import perfil from '../../../assets/img/perfil.png';
import voltar from '../../../assets/img/voltar.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import editar from '../../../assets/img/editar.png';
import excluir from '../../../assets/img/excluir.png';
import './playlists.scss';

export default function Playlists({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const [playlists, setPlaylists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchPlaylists() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getPlaylists`, { fingerprint: fpHash }, config);

                if (response.data.success) {
                    const playlists = response.data.playlists;

                    setPlaylists(playlists);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        if (fpHash) {
            fetchPlaylists();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleOpenPlaylist = (id_playlist) => {
        navigate(`/playlist/${id_playlist}`);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const handleDeletePlaylist = async (id_playlist) => {
        swal({
            title: "Deseja excluir a playlist?",
            text: `A playlist será excluída permanentemente!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                try {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                        navigate('/');
                        return;
                    }

                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };
                    const response = await axios.post(`${uri}/deletePlaylist`, {id_playlist, fingerprint: fpHash}, config);
                    if (response.data.success) {
                        const updatedPlaylists = playlists.filter(playlist => playlist.id !== id_playlist);
                        setPlaylists(updatedPlaylists);
                    } else {
                        swal("Erro ao excluir", "Ocorreu um erro ao excluir a playlist", "error");
                    }
                } catch (error) {
                    swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error")
                }
            }
        });
    }

    const handleUpdatePlaylist = (id_playlist, name_playlist) => {
        navigate(`/editarPlaylist/${id_playlist}/${name_playlist}`);
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const filteredPlaylists = playlists.filter(playlist => 
        playlist.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                        
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <div className='selected-line'></div>
                        <img onClick={handleRedirectPlaylists} className="playlists selected-page" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='relatorios'>
                    <div className='relatorios-card'>
                        <h1>Suas Playlists</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate(`/relatorios`)} />
                            <img src={images.addImage} onClick={() => navigate('/adicionarPlaylist')} alt='Novo'/>
                        </div>
                        <div className='search-bar'>
                            <input 
                                type="text" 
                                placeholder="Pesquisar playlists..." 
                                value={search} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                        <div className='relatorios-list'>
                            {loading ? (
                                <div className="loading-spinner textReport">
                                    <ClipLoader color="#000000" loading={loading} size={50} /> 
                                </div>
                            ) : filteredPlaylists.length > 0 ? (
                                filteredPlaylists.map((playlist, index) => (
                                    <div className='group-playlists' key={index}>
                                        <div className='actionsGroupPlaylist'>
                                            <img src={images.editImage} onClick={() => handleUpdatePlaylist(playlist.id, playlist.name)} alt='Editar'/>
                                            <img src={excluir} onClick={() => handleDeletePlaylist(playlist.id)} alt='Editar'/>
                                        </div>
                                        <div className='playlist-redirect' onClick={() => handleOpenPlaylist(playlist.id)}>
                                            <img className='playlist-img' src={images.playlistImage}/>
                                            <span>{playlist.name}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span className='textReport'>Nenhuma playlist disponível</span>
                            )}
                        </div>
                        {/* <span className='desc-playlists'>Playlists = Grupo de um ou mais relatórios na qual cada tela do relatório é percorrida automaticamente.</span> */}
                    </div>
                </div>
            </div>
        </section>
    );
}