import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import voltar from '../../../assets/img/voltar.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import './adicionarPlaylistReport.scss';

export default function AdicionarPlaylistReport({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const { id_playlist } = useParams();
    const [tempoAtualizacao, setTempoAtualizacao] = useState('');
    const [selectedReport, setSelectedReport] = useState('');
    const [selectedNavegacao, setSelectedNavegacao] = useState('');
    const [reports, setReports] = useState([]);

    useEffect(() => {
        async function fetchReports() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getUserReports`, { fingerprint: fpHash }, config);

                if (response.data.success) {
                    const reports = response.data.reports;

                    setReports(reports);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            }
        }

        if (fpHash) {
            fetchReports();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const handleSubmitNewPlaylistReport = async (e) => {
        e.preventDefault();

        if (tempoAtualizacao === '') {
            swal('Erro', 'O tempo de atualização da playlist não pode ser vazio', 'error');
            return;
        } else if (selectedReport === '') {
            swal('Erro', 'Selecione um relatório', 'error');
            return;
        } else if(selectedNavegacao === '') {
            swal('Erro', 'Selecione uma opção de navegação', 'error');
            return;
        }

        try {
            const token = sessionStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.post(`${uri}/insertPlaylistReport`, {
                id_playlist: id_playlist,
                tempo_atualizacao: tempoAtualizacao,
                id: selectedReport,
                navegacao: selectedNavegacao,
                fingerprint: fpHash
            }, config);

            if (response.status === 200) {
                swal('Sucesso', 'Relatório adicionado com sucesso', 'success');
                navigate(`/playlist/${id_playlist}`);
            }
        } catch (error) {
            swal('Erro', error.response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
        }
    }

    const handlePlaylistReport = (e) => {
        setSelectedReport(e.target.value);
    }

    const handleNavegacao = (e) => {
        setSelectedNavegacao(e.target.value);
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='add-playlist-container'>
                    <div className='add-playlist-card'>
                        <h1>Adicionar Relatório - Playlist</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate(`/playlist/${id_playlist}`)} />
                        </div>
                        <div className='add-playlist-container-form'>
                            <form onSubmit={(e) => handleSubmitNewPlaylistReport(e)} className='form-add-playlist'>
                                <label htmlFor='add-playlist-input' className='add-playlist-label-input-first'>Relatório:</label>
                                <select value={selectedReport} id='playlist_report' onChange={(e) => handlePlaylistReport(e)}>
                                    <option value="" disabled>Selecione um relatório</option>
                                    {reports.map((report, index) => (
                                    <option key={index} value={report.id_user_report}>
                                        {report.name}
                                    </option>
                                    ))}
                                </select>
                                <label htmlFor='add-playlist-input'>Tempo de atualização (segundos):</label>
                                <input placeholder='Tempo de atualização...' onChange={(e) => setTempoAtualizacao(e.target.value)} type='number' name='add-playlist-input' id='add-playlist-input' />
                                <label htmlFor='add-playlist-input'>Relatório Navegável?</label>
                                <select value={selectedNavegacao} id='playlist_report' onChange={(e) => handleNavegacao(e)}>
                                    <option value="" disabled>Selecione se é navegável</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                </select>
                                <button className='button-playlist-report' type='submit'>Adicionar Relatório</button>
                            </form>
                        </div>
                        <span className='obs-playlist'>Obs: relatório navegável não tem as telas percorridas automáticamente, e o tempo de atualização definido é o de um dashboard para o outro.</span>
                    </div>
                </div>
            </div>
        </section>
    );
}