import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import logout from '../../../assets/img/logout.png';
import './grupos.scss';

export default function Grupos({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const [grupos, setGrupos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchGroups() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getUserGroups`, { fingerprint: fpHash }, config);

                if (response.data.success) {
                    const gruposData = response.data.groups;

                    setGrupos(gruposData);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        if (fpHash) {
            fetchGroups();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const handleOpenGroup = (id_grupo, name_grupo) => {
        navigate(`/grupo/${id_grupo}/${name_grupo}`);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredGroups = grupos?.filter(grupo => 
        grupo.name.toLowerCase().includes(search.toLowerCase())
    ) || [];

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                        
                    
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                     
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <div className='selected-line'></div>
                        <img onClick={handleRedirectHome} className="home selected-page" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                        
                </div>
                <div className='relatorios'>
                   
                    <div className='relatorios-card'>
                        <h1>Seus Grupos de Relatórios</h1>
                        <div className='search-bar'>
                            <input 
                                type="text" 
                                placeholder="Pesquisar grupos..." 
                                value={search} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                        <div className='relatorios-list'>
                            {loading ? (
                                <div className="loading-spinner textReport">
                                    <ClipLoader color="#000000" loading={loading} size={50} /> 
                                </div>
                            ) : filteredGroups.length > 0 ? (
                                filteredGroups.map((grupo, index) => (
                                    <div className='group-group' onClick={() => handleOpenGroup(grupo.id, grupo.name)} key={index}>
                                        <img className='img-group-report' src={relatorios}/>
                                        <span>{grupo.name}</span>
                                    </div>
                                ))
                            ) : (
                                <span className='textReport'>Nenhum grupo disponível</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}