import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import voltar from '../../../assets/img/voltar.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import './adicionarPlaylist.scss';

export default function AdicionarPlaylist({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const [newPlaylist, setNewPlaylist] = useState('');

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const handleSubmitNewPlaylist = async (e) => {
        e.preventDefault();

        if (newPlaylist === '') {
            swal('Erro', 'O nome da playlist não pode ser vazio', 'error');
            return;
        }

        try {
            const token = sessionStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.post(`${uri}/insertPlaylist`, {
                name: newPlaylist,
                fingerprint: fpHash
            }, config);

            if (response.status === 200) {
                swal('Sucesso', 'Playlist adicionada com sucesso', 'success');
                navigate('/playlists');
            }
        } catch (error) {
            swal('Erro', 'Erro ao adicionar playlist', 'error');
        }
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                        
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    
                  
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='add-playlist-container'>
                    <div className='add-playlist-card'>
                        <h1>Adicionar Playlist</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate('/playlists')} />
                        </div>
                        <div className='add-playlist-container-form'>
                            <form onSubmit={(e) => handleSubmitNewPlaylist(e)} className='form-add-playlist'>
                                <label htmlFor='add-playlist-input'>Nome:</label>
                                <input placeholder='Nome da playlist...' onChange={(e) => setNewPlaylist(e.target.value)} type='text' name='add-playlist-input' id='add-playlist-input' />
                                <button type='submit'>Adicionar Playlist</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}