import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { uri } from '../../../configs_client';
import editar from '../../../assets/img/editar.png';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';
import './grupoRelatorios.scss';

export default function GrupoRelatorios(props) {

    const navigate = useNavigate();
    const [groupReports, setGroupReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchGroupsReports() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/groupReports`, {id_group: props.group}, config);
                if (response.data.success) {
                    if (response.data.reports.length === 0) {
                        setGroupReports([]);
                        return;
                    }
                    const groupsReportsData = response.data.reports;
                    
                    const updatedReports = await Promise.all(
                        groupsReportsData.map(async (report) => {
                            const imageUrl = await isImageValid(`${uri}/${report.path_img}`);
                            return { ...report, imageUrl };
                        })
                    );
                    
                    setGroupReports(updatedReports);
                } else {
                    navigate('/');

                }
            } catch (error) {
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchGroupsReports();
        
    }, []);

    async function isImageValid(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(url);
            img.onerror = () => resolve(`${uri}/assets/reports/default.png`);
            img.src = url;
        });
    }

    async function deleteGroupReport(id) {
        const report = groupReports.find(report => report.id === id);
        const reportName = report.name;
        swal({
            title: "Deseja excluir o relatório?",
            text: `O relatório: ${reportName}, será excluído permanentemente do grupo!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                try {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                        navigate('/');
                        return;
                    }

                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };
                    const response = await axios.post(`${uri}/deleteGroupReport`, {id_report: id, id_group: props.group}, config);
                    if (response.data.success) {
                        const updatedReports = groupReports.filter(report => report.id !== id);
                        setGroupReports(updatedReports);
                    } else {
                        swal("Erro ao excluir", "Ocorreu um erro ao excluir o relatório", "error");
                    }
                } catch (error) {
                    swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente.", "error")
                }
            }
          });
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredGroupReports = groupReports.filter(report => 
        report.name.toLowerCase().includes(search.toLowerCase())
    );

    return(
        <>
            <h1>{props.name}</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')}/>
                <img src={props.images.addImage} alt='Novo' onClick={() => navigate(`/adicionarRelatorio/${props.group}/${props.name}`)}/>
            </div>
            <div className='search-bar'>
                <input 
                    type="text" 
                    placeholder="Pesquisar relatórios..." 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className='default-list'>
                {loading ? (
                    <div className="loading-spinner textReport">
                        <ClipLoader color="#000000" loading={loading} size={50} /> 
                    </div>
                ) : filteredGroupReports.length > 0 ? (
                    filteredGroupReports.map((report, index) => (
                        <div className='group-relatorio noHover' key={index}>
                            <div className='actionsGroup'>
                                <img src={props.images.editImage} alt='Editar' onClick={() => navigate(`/editarRelatorio/${props.group}/${props.name}/${report.id}`)}/>
                                <img src={excluir} alt='Editar' onClick={() => deleteGroupReport(report.id)}/>
                            </div>
                            <img src={report.imageUrl} className='report-img' alt={report.name} />
                            <span>{report.name}</span>
                        </div>
                    ))
                ) : (
                    <span className='textReport'>Nenhum relatório disponível</span>
                )}
            </div>
        </>
    )
}