import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { uri } from '../../../configs_client';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';
import './adicionarGrupo.scss';

export default function AdicionarGrupo(props) {
    const navigate = useNavigate();
    const [nameGroup, setNameGroup] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [grupo, setGrupo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/users`, {}, config);
                if (response.data.success) {
                    const users = response.data.users;
                    // Sort users alphabetically by name
                    users.sort((a, b) => a.name.localeCompare(b.name));
                    setUsers(users);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching reports and images:', error);
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchUsers();
    }, [navigate]);

    const handleSelectUser = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleAddUser = () => {
        if (selectedUser && !selectedUsers.some(user => user.id === parseInt(selectedUser))) {
            const user = users.find(user => user.id === parseInt(selectedUser));
            if (user) {
                setSelectedUsers([...selectedUsers, user]);
                setSelectedUser(''); // Clear the selection after adding
            }
        }
    };

    const handleChangeName = (event) => {
        setNameGroup(event.target.value); // Update nameGroup state with input value
    };

    const handleDeleteSelectedUser = (id) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== id));
    };

    async function handleSubmit(event) {
        event.preventDefault();

        if (!nameGroup) {
            swal('Atenção!', 'Informe o nome do grupo.', 'warning');
            return;
        }

        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                navigate('/');
                return;
            }

            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            console.log(nameGroup)
            const data = {
                name: nameGroup, // Ensure `grupo.nome` is correctly defined
                users: selectedUsers.map(user => user.id) // Only IDs of selected users
            };

            const response = await axios.post(`${uri}/insertGroup`, data, config);
            if (response.data.success) {
                navigate('/gerenciamento');
            } else {
                swal('Erro!', response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response.data.error, "error");
        }
    };

    return (
        <>
            <h1>Adicionar Grupo</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')} />
            </div>
            <div className='default-list'>
                {loading ? (
                    <div className="loading-spinner textReport">
                        <ClipLoader color="#000000" loading={loading} size={50} /> 
                    </div>
                ) : (
                    <form className='new-grupo' onSubmit={handleSubmit}>
                        <div className='left-side'>
                            <label htmlFor='name_group_add'>Nome:</label>
                            <input id='name_group_add' type='text' value={nameGroup} onChange={handleChangeName} placeholder='Nome do grupo' required />
                        </div>
                        <div className='right-side'>
                            <h2>Associar Usuários:</h2>
                            <label htmlFor='users_group_add'>Selecionar Usuário:</label>
                            <select id='users_group_add' value={selectedUser} onChange={handleSelectUser}>
                                <option value="" disabled>Selecione um usuário</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                            <div className='add-user' onClick={handleAddUser}>
                                <img src={props.images.addImage} alt="Adicionar" />
                                <span>Adicionar</span>
                            </div>
                            <div className='selected-users'>
                                <h3>Usuários Selecionados:</h3>
                                <ul>
                                    {selectedUsers.map(user => (
                                        <li key={user.id}>- {user.name}<img src={excluir} onClick={() => handleDeleteSelectedUser(user.id)} /></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <span className='info'>Obs: você poderá associar usuários posteriormente apenas a relatórios específicos do grupo.</span>
                        <button className='criar-grupo' type='submit'>Criar Grupo</button>
                    </form>
                )}
            </div>
        </>
    )
}
