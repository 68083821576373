import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { uri } from '../../../configs_client';
import editar from '../../../assets/img/editar.png';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';

import './usuarios.scss';

export default function Usuarios(props) {

    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchUsers() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/users`, {}, config);
                if (response.data.success) {
                    const users = response.data.users;
                    users.sort((a, b) => a.name.localeCompare(b.name));

                    setUsers(users);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching reports and images:', error);
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchUsers();
        
    }, []);

    async function deleteUser(id) {
        const user = users.find(user => user.id === id);
        const userName = user.name;
        swal({
            title: "Deseja excluir o usuário?",
            text: `O usuário: ${userName}, será excluído permanentemente!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                try {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                        navigate('/');
                        return;
                    }
        
                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };
        
                    const response = await axios.post(`${uri}/deleteUser`, { id_user: id }, config);
        
                    if (response.data.success) {
                        setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
                        swal ( "Usuário excluído!" ,  "Usuário excluído com sucesso!" ,  "success" )
                    } 
                } catch (error) {
                    swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente.", "error")
                } finally {
                    setLoading(false);
                }
            }
          });
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredUsers = users.filter(user => 
        user.name.toLowerCase().includes(search.toLowerCase())
    );

    return(
        <>
            <h1>Usuários</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')}/>
                <img src={props.images.addImage} alt='Novo' onClick={() => navigate('/adicionarUsuario')}/>
            </div>
            <div className='search-bar'>
                <input 
                    type="text" 
                    placeholder="Pesquisar usuários..." 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className='default-list'>
                {loading ? (
                    <div className="loading-spinner textReport">
                        <ClipLoader color="#000000" loading={loading} size={50} /> 
                    </div>
                ) : filteredUsers.length > 0 ? (
                    <table className='users-table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td className='actions-user'>
                                        <img src={props.images.editImage} alt='Editar' onClick={() => navigate(`/editarUsuario/${user.id}`)}/>
                                        <img src={excluir} alt='Excluir' onClick={() => deleteUser(user.id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <span className='textReport'>Nenhum usuário disponível</span>
                )}
            </div>
        </>
    )
}