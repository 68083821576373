import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import voltar from '../../../assets/img/voltar.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import './perfil.scss';

export default function Perfil({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    async function handleSubmitNewPassword(e) {
        e.preventDefault();
        if (!oldPassword || !newPassword) {
            swal('Campos faltantes!', 'Por favor, preencha todos os campos', 'warning');
            return;
        }
    
        // Verificação de complexidade da nova senha
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
        if (!passwordRegex.test(newPassword) || newPassword.length < 6) {
            swal('Senha fraca!', 'A nova senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula e um número.', 'warning');
            return;
        }
    
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }
    
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
    
        try {
            const response = await axios.post(`${uri}/updatePassword`, { old_password: oldPassword, new_password: newPassword, fingerprint: fpHash }, config);
            if (response.data.success) {
                navigate('/relatorios');
            } else {
                swal('Erro!', response.data.error, 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
        }
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                       
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />

                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='perfil-container'>
                    <div className='perfil-card'>
                        <h1>Mudar Senha</h1>
                        <div className='page-actions-perfil'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate('/relatorios')} />
                        </div>
                        <div className='perfil-container-form'>
                            <form onSubmit={(e) => handleSubmitNewPassword(e)} className='form-pass'>
                                <label htmlFor='pass-perfil'>Senha Atual:</label>
                                <input onChange={(e) => setOldPassword(e.target.value)} type='password' name='pass-perfil' id='pass-perfil' />
                                <label htmlFor='new-pass-perfil'>Nova Senha:</label>
                                <input onChange={(e) => setNewPassword(e.target.value)} type='password' name='new-pass-perfil' id='new-pass-perfil' />
                                <span>A senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula e um número.</span>
                                <button type='submit'>Mudar Senha</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}