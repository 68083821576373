import React, { useState } from "react";
import swal from "sweetalert";

import "./datasetFilter.scss";
import excluir from '../../assets/img/excluir.png';

export default function DatasetFilter({ infosDataset, setSelectedUsers, setIsDatasetFiltersOpened, images }) {
    const [filters, setFilters] = useState(
        infosDataset.filtersUser && infosDataset.filtersUser.length > 0 ? infosDataset.filtersUser : [{ table: "", column: "", condition: "", value: "", data_type: "" }]
    );

    function handleSubmitNewFilter(event) {
        event.preventDefault();

        // Verificação se o primeiro filtro está completamente vazio e é o único
        if (filters.length === 1 && !filters[0].table && !filters[0].column && !filters[0].condition && !filters[0].value && !filters[0].data_type) {
            setSelectedUsers(users => users.map(user => (user.id === infosDataset.id_user ? { ...user, filters: [] } : user)));
            setIsDatasetFiltersOpened(false);
            return;
        }

        let newFilters = [...filters];

        if(!filters[filters.length - 1].table && !filters[filters.length - 1].column && !filters[filters.length - 1].condition && !filters[filters.length - 1].value && !filters[filters.length - 1].data_type) {
            setFilters(filters.slice(0, filters.length - 1));
            newFilters = filters.slice(0, filters.length - 1);
        }

        // Verificação de preenchimento dos campos
        const isValid = newFilters.every((filter, index) => (
            (filter.table && filter.column && filter.condition && filter.value && filter.data_type)
        ));

        if (!isValid) {
            swal("Erro", "Por favor, preencha todos os campos dos filtros antes de aplicar.", "error");
            return;
        }

        const isDataTypeCorrectly = filters.every(filter => {
            if (filter.data_type === 'number') {
                return !isNaN(filter.value);
            }
            return true; // Para filtros que não são do tipo 'number', retorna true
        });
    
        if (!isDataTypeCorrectly) {
            swal("Erro", "Por favor, preencha o campo valor com o tipo de dado correto.", "error");
            return;
        }

        // Filtrar apenas os filtros preenchidos
        const validFilters = newFilters.filter(filter => filter.table || filter.column || filter.condition || filter.value || filter.data_type);

        // Atualizar usuários com os novos filtros válidos
        setSelectedUsers(users => users.map(user => (
            user.id === infosDataset.id_user ? { ...user, filters: validFilters } : user
        )));

        // Fechar o modal de filtros
        setIsDatasetFiltersOpened(false);
    }
    function handleAddOtherFilter() {
        // Verifica se há algum filtro vazio antes de adicionar um novo
        const isEmpty = filters.some(filter => !filter.table || !filter.column || !filter.condition || !filter.value || !filter.data_type);
        
        if (isEmpty) {
            swal("Erro", "Por favor, preencha todos os campos do filtro antes de adicionar um novo.", "error");
            return;
        }
        
        const isDataTypeCorrectly = filters.every(filter => {
            if (filter.data_type === 'number') {
                return !isNaN(filter.value);
            }
            return true; // Para filtros que não são do tipo 'number', retorna true
        });
    
        if (!isDataTypeCorrectly) {
            swal("Erro", "Por favor, preencha o campo valor com o tipo de dado correto.", "error");
            return;
        }

        setFilters([...filters, { table: "", column: "", condition: "", value: "", data_type: "" }]);
    }

    function handleChangeFilter(index, event) {
        const { name, value } = event.target;
        const newFilters = filters.map((filter, i) => (
            i === index ? { ...filter, [name]: value } : filter
        ));
        setFilters(newFilters);
    }

    function handleDeleteFilter(index) {
        if (index === 0 && filters.length === 1) {
            // Limpar campos do primeiro filtro ao invés de deletar
            setFilters([{ table: "", column: "", condition: "", value: "", data_type: "" }]);
        } else {
            // Remover filtro pelo índice
            setFilters(filters.filter((_, i) => i !== index));
        }
    }

    return (
        <div className="default-list infos-dataset">
            <p className="infos-dataset-name">Usuário: {infosDataset.name_user}</p>
            <div className="infos-dataset-filters">
                <h3>Aplicar Filtros:</h3>
                <p>Selecione abaixo os filtros de relatório:</p>
                <form onSubmit={handleSubmitNewFilter}>
                    {filters.map((filter, index) => (
                        <div key={index} className="form-group-infos-dataset">
                            <input 
                                name="table" 
                                placeholder="Tabela" 
                                value={filter.table} 
                                onChange={event => handleChangeFilter(index, event)} 
                            />
                            <input 
                                name="column" 
                                placeholder="Coluna" 
                                value={filter.column} 
                                onChange={event => handleChangeFilter(index, event)} 
                            />
                            <select 
                                name="condition" 
                                value={filter.condition} 
                                onChange={event => handleChangeFilter(index, event)} 
                                className="condition-filter"
                            >
                                <option value="" disabled>Condição</option>
                                <option value="In">É</option>
                                <option value="NotIn">Não É</option>
                            </select>
                            <input 
                                name="value" 
                                placeholder="Valor" 
                                value={filter.value} 
                                onChange={event => handleChangeFilter(index, event)} 
                            />
                            <select 
                                name="data_type" 
                                value={filter.data_type} 
                                onChange={event => handleChangeFilter(index, event)} 
                            >
                                <option value="" disabled>Tipo</option>
                                <option value="number">Numérico</option>
                                <option value="varchar">Alfanumérico</option>
                            </select>
                            {index === 0 && (
                                <img src={excluir} alt="Limpar" onClick={() => handleDeleteFilter(index)} />
                            )}
                            {index > 0 && (
                                <img src={excluir} alt="Excluir" onClick={() => handleDeleteFilter(index)} />
                            )}
                        </div>
                    ))}
                    <div className='add-filter' onClick={handleAddOtherFilter}>
                        <img src={images.addImage} alt="Adicionar" />
                        <span>Adicionar outro</span>
                    </div>
                    <button type="submit">Aplicar Filtros</button>
                </form>
            </div>
        </div>
    );
}