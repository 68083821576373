import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../configs_client';
import { useParams } from 'react-router-dom';

import settings from '../../assets/img/settings.png';
import users from '../../assets/img/users.png';
import relatoriosMenu from '../../assets/img/relatorios-menu.png';
import suporteMenu from '../../assets/img/suporte.png';
import home from '../../assets/img/home.png';
import perfil from '../../assets/img/perfil.png';
import telaCheia from '../../assets/img/tela_cheia.png';
import logout from '../../assets/img/logout.png';

import './default.scss';

export default function Default({ fpHash, expanded, expRelatorio, containerRef, images, texts, ...props}) {
    const navigate = useNavigate();
    const params = useParams();
    const { children } = props;

    // Combinando as props com os parâmetros da URL
    const allProps = { ...props, ...params, images };

    // Clonando o filho e passando allProps como novas props
    const clonedChildren = React.Children.map(children, child => 
        React.cloneElement(child, allProps)
    );


    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectGerenciamento = () => {
        navigate('/gerenciamento');
    }

    useEffect(() => {
        async function verifyToken() {
            !sessionStorage.getItem('token') ?? navigate('/');
        }
        verifyToken();
    }, []);

    return (
        <section ref={containerRef} className='default-general'>
            {images.fundoRelatorios ? 
                <img className="backgroundDefault" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' src={perfil} onClick={() => navigate('/perfilAdm')} alt="Perfil" />
                </div>
                <div className="menuLeft">
                    <img onClick={handleRedirectGerenciamento} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    <div className='menuLeft-icon'>
                        <div className={props.lineClass == 'gerenciamento-line' ? 'selected-line-adm' : ''}></div>
                        <img className={props.lineClass == 'gerenciamento-line' ? 'home-adm page-selected-adm' : 'home-adm'} src={home} alt="Home" onClick={() => navigate('/gerenciamento')} />
                    </div>
                    <div className='menuLeft-icon'>
                        <div className={props.lineClass == 'usuarios-line' ? 'selected-line-adm' : ''}></div>
                        <img className={props.lineClass == 'usuarios-line' ? 'users page-selected-adm' : 'users'} src={users} alt="Users" onClick={() => navigate('/usuarios')} />
                    </div>
                    <div className='menuLeft-icon'>
                        <div className={props.lineClass == 'relatorios-menu-line' ? 'selected-line-adm' : ''}></div>
                        <img className={props.lineClass == 'relatorios-menu-line' ? 'relatorios-menu page-selected-adm' : 'relatorios-menu'} src={relatoriosMenu} alt="Relatórios Gerenciais" onClick={() => navigate('/relatoriosGerenciais')} />
                    </div>
                    <div className='menuLeft-icon'>
                        <div className={props.lineClass == 'suporte-menu-line' ? 'selected-line-adm' : ''}></div>
                        <img className={props.lineClass == 'suporte-menu-line' ? 'suporte-menu page-selected-adm' : 'suporte-menu'} src={suporteMenu} alt="Relatórios Gerenciais" onClick={() => navigate('/suporte')} />
                    </div>
                    {/* <img className="settings" src={settings} alt="Settings" title='Usuários' /> */}
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='default'>
                    <div className='default-card'>
                        {clonedChildren}
                    </div>
                </div>
            </div>
        </section>
    );
}