import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import voltar from '../../../assets/img/voltar_menu.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import { texts } from '../../../configs_client';
import PowerBIReport from '../../../components/embed/PowerBiReport';
import PowerBIReportPlaylist from '../../../components/embed_playlist/PowerBiReportPlaylist';

export default function PlaylistRun({fpHash, expanded, expRelatorio, containerRef, images, texts}) {
    const { id_playlist } = useParams();
    const navigate = useNavigate();
    const expandedReportWidth = !expanded ? '70%' : '100%';
    const expandedReportHeight = !expanded ? '85%' : '93vh';

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='voltarMenuTop' src={voltar} alt='Voltar' onClick={() => navigate(`/playlist/${id_playlist}`)} />
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                        
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                </div>
                <div className="menuLeft">
                <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className={`content ${expanded ? 'expandido' : ''}`}>
                    <PowerBIReportPlaylist fpHash={fpHash} id_playlist={id_playlist} width={expandedReportWidth} height={expandedReportHeight} borderRadius={!expanded} />
                    {!expanded ? (
                        <span>Está com alguma dúvida? Entre em contato: <a href={`mailto:${texts.suporteEmail}`}>suporte@soaresconsulting.com.br</a></span>    
                    ) : null}
                </div>
            </div>
        </section>
    );
}