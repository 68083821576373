import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import users from '../../../assets/img/users.png';
import relatoriosMenu from '../../../assets/img/relatorios-menu.png';
import home from '../../../assets/img/home.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import voltar from '../../../assets/img/voltar_menu.png';
import { texts } from '../../../configs_client';
import './visualizarRelatorio.scss';
import PowerBIReport from '../../../components/embed/PowerBiReport';

export default function VisualizarRelatorio({fpHash, expanded, expRelatorio, containerRef, images, texts}) {
    const { id_report } = useParams();
    const navigate = useNavigate();
    const expandedReportWidth = !expanded ? '70%' : '100%';
    const expandedReportHeight = !expanded ? '85%' : '93vh';

    const handleRedirectGerenciamento = () => {
        navigate('/gerenciamento');
    }

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
            <div className="menuTop">
                <img className='voltarMenuTop' src={voltar} alt='Voltar' onClick={() => navigate('/relatoriosGerenciais')} />
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' src={perfil} onClick={() => navigate('/perfilAdm')} alt="Perfil" />
                </div>
                <div className="menuLeft">
                    <img onClick={handleRedirectGerenciamento} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    <div className='menuLeft-icon'>
                        <div className='gerenciamento-line'></div>
                        <img className='home-adm' src={home} alt="Home" onClick={() => navigate('/gerenciamento')} />
                    </div>
                    <div className='menuLeft-icon'>
                        <div className='usuarios-line'></div>
                        <img className='users' src={users} alt="Users" onClick={() => navigate('/usuarios')} />
                    </div>
                    <div className='menuLeft-icon'>
                        <div className='relatorios-menu-line'></div>
                        <img className='relatorios-menu' src={relatoriosMenu} alt="Relatórios Gerenciais" onClick={() => navigate('/relatoriosGerenciais')} />
                    </div>
                    {/* <img className="settings" src={settings} alt="Settings" title='Usuários' /> */}
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className={`content ${expanded ? 'expandido' : ''}`}>
                    <PowerBIReport fpHash={fpHash} gerencial={true} id_report={id_report} width={expandedReportWidth} height={expandedReportHeight} borderRadius={!expanded} />
                    {!expanded ? (
                        <span>Está com alguma dúvida? Entre em contato: <a href={`mailto:${texts.suporteEmail}`}>suporte@soaresconsulting.com.br</a></span>    
                    ) : null}
                </div>
            </div>
        </section>
    );
}