import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { uri } from '../../../configs_client';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';
import filters from '../../../assets/img/user_filters.png';
import './adicionarRelatorio.scss';
import DatasetFilter from '../../../components/datasetFilter/DatasetFilter';

export default function AdicionarRelatorio(props) {

    const navigate = useNavigate();
    const [isDatasetFiltersOpened, setIsDatasetFiltersOpened] = useState(false);
    const [infosDatasetFilters, setInfosDatasetFilters] = useState({}); // Inicializar como objeto vazio
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [nameReport, setNameReport] = useState(''); // Inicializar como string vazia
    const [users, setUsers] = useState([]); // Inicializar como array vazio
    const [accounts, setAccounts] = useState([]); // Inicializar como array vazio
    const [selectedAccount, setSelectedAccount] = useState('');
    const [workspaces, setWorkspaces] = useState([]); // Inicializar como array vazio
    const [selectedWorkspace, setSelectedWorkspace] = useState(''); // Inicializar como array vazio
    const [relatorios, setRelatorios] = useState([]); // Inicializar como array vazio
    const [selectedRelatorio, setSelectedRelatorio] = useState(''); // Inicializar como array vazio
    const [selectedFile, setSelectedFile] = useState(null);
    const [pathImg, setPathImg] = useState(uri + '/assets/reports/default.png'); // Inicializar como string vazia
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/users`, {}, config);
                if (response.data.success) {
                    const users = response.data.users;
                    users.sort((a, b) => a.name.localeCompare(b.name));
                    setUsers(users);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            }
        }

        async function fetchAccounts() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/accounts`, {}, config);
                if (response.data.success) {
                    const accounts = response.data.contas;
                    setAccounts(accounts);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        async function fetchGroupUsers () {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/groupUsers`, {id_group: props.id_grupo}, config);
                if (response.data.success) {
                    const users = response.data.users;
                    setSelectedUsers(users);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');  
            }
        }
        
        fetchUsers();
        fetchGroupUsers();
        fetchAccounts();
        console.log(props)
        
    }, [navigate, props.id_grupo]);

    useEffect(() => {
        async function fetchWorkspaces() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/workspaces`, {id_conta: selectedAccount}, config);
                if (response.data.success) {
                    const workspacesData = response.data.workspaces;
                    setWorkspaces(workspacesData);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            }
        }

        if(selectedAccount != '') {
            fetchWorkspaces();
        }
    }, [selectedAccount, navigate]);

    useEffect(() => {
        async function fetchRelatorio() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/reports`, {id_conta: selectedAccount, id_workspace: selectedWorkspace}, config);
                if (response.data.success) {
                    const reportsData = response.data.reports;
                    setRelatorios(reportsData);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } 
        }
        if(selectedWorkspace != '') {
            fetchRelatorio();
        }
    }, [selectedWorkspace, navigate, selectedAccount]);
    
    const handleSelectUser = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPathImg(reader.result);
              };
            reader.readAsDataURL(e.target.files[0]);
        } 
    };

    const handleAddUser = () => {
        if (selectedUser && !selectedUsers.some(user => user.id === parseInt(selectedUser))) {
            const user = users.find(user => user.id === parseInt(selectedUser));
            if (user) {
                setSelectedUsers([...selectedUsers, user]);
                setSelectedUser(''); // Limpar a seleção após adicionar
            }
        }
    };

    const handleChangeName = (event) => {
        setNameReport(event.target.value); // Atualiza o estado nameGroup com o valor do input
    };

    const handleDeleteSelectedUser = (id) => {
        setSelectedUsers(selectedUsers.filter(user => user.id !== id));
    };

    async function handleDeleteImage() {
        setPathImg(uri + '/assets/reports/default.png');
        setSelectedFile(null);
    }

    async function handleSubmit(event) {
        event.preventDefault();
    
        if (!nameReport) {
            swal('Atenção!', 'Informe o nome do relatório.', 'warning');
            return;
        }
        if (!selectedAccount) {
            swal('Atenção!', 'Informe a conta do relatório.', 'warning');
            return;
        }
        if (!selectedWorkspace) {
            swal('Atenção!', 'Informe o workspace do relatório.', 'warning');
            return;
        }
        if (!selectedRelatorio) {
            swal('Atenção!', 'Informe o relatório.', 'warning');
            return;
        }
    
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                navigate('/');
                return;
            }
    
            const formData = new FormData();
            formData.append('name', nameReport);
            formData.append('id_group', props.id_grupo);
            formData.append('id_conta', selectedAccount);
            formData.append('id_report', selectedRelatorio);
            formData.append('id_workspace', selectedWorkspace);
            formData.append('users', JSON.stringify(selectedUsers.map(user => ({
                id: user.id,
                filters: user.filters
            }))));
            formData.append('image', selectedFile); // adicionar a imagem selecionada
            
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
    
            const response = await axios.post(`${uri}/insertGroupReport`, formData, config);
            if (response.data.success) {
                navigate(`/grupoRelatorios/${props.id_grupo}/${props.name}`);
            } else {
                swal('Erro!', response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
        }
    };

    function handleAccount(event) {
        const { value } = event.target;
        setSelectedAccount(value);
    }

    function handleWorkspace(event) {
        const { value } = event.target;
        setSelectedWorkspace(value);
    }

    function handleRelatorio(event) {
        const { value } = event.target;
        setSelectedRelatorio(value);
    }

    function handleOpenUpdateFilters(id_user, name_user) {
        setIsDatasetFiltersOpened(true);
        const user = selectedUsers.find(user => user.id === id_user);
        const filtersUser = user.filters ?? [];
        setInfosDatasetFilters({id_user, name_user, filtersUser});
    }

    return(
        <>
            <h1>Adicionar Relatório</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => isDatasetFiltersOpened ? setIsDatasetFiltersOpened(false) : navigate(`/grupoRelatorios/${props.id_grupo}/${props.name}`)} />
            </div>
            {!isDatasetFiltersOpened ? (
            <div className='default-list'>
                {loading ? (
                    <div className="loading-spinner textReport">
                        <ClipLoader color="#000000" loading={loading} size={50} /> 
                    </div>
                ) : (
                    <form className='new-grupo' onSubmit={handleSubmit} enctype="multipart/form-data">
                        <div className='left-side'>
                            <label htmlFor='name_report_add'>Nome:</label>
                            <input id='name_report_add' type='text' onChange={handleChangeName} placeholder='Nome do relatório' required />
                            <label htmlFor='conta_report_add'>Conta:</label>
                            <select id='conta_report_add' value={selectedAccount} onChange={(e) => handleAccount(e)}>
                                <option value="" disabled>Selecione uma conta</option>
                                {accounts.map((account, index) => (
                                <option key={index} value={account.id}>
                                    {account.name}
                                </option>
                                ))}
                            </select>
                            <label htmlFor='workspace_report_add'>Workspace:</label>
                            <select id='workspace_report_add' value={selectedWorkspace} onChange={(e) => handleWorkspace(e)}>
                                <option value="" disabled>Selecione um Workspace</option>
                                {workspaces.map((workspace, index) => (
                                <option key={index} value={workspace.id}>
                                    {workspace.name}
                                </option>
                                ))}
                            </select>
                            <label htmlFor='link_report_add'>Relatório:</label>
                            <select id='link_report_add' value={selectedRelatorio} onChange={(e) => handleRelatorio(e)}>
                                <option value="" disabled>Selecione um relatório</option>
                                {relatorios.map((relatorio, index) => (
                                <option key={index} value={relatorio.id}>
                                    {relatorio.name}
                                </option>
                                ))}
                            </select>
                            <label>Imagem:</label>
                            <div className='div-image-report'>
                                <img src={pathImg} alt='Imagem do relatório' className='img-report' />
                                {pathImg != uri + '/assets/reports/default.png' &&
                                    <img src={excluir} onClick={() => handleDeleteImage()} className='excluir-img-report' />
                                }
                            </div>
                            <input accept="image/*" type='file' onChange={handleFileChange} className='uploadReportImage' name='file' />
                        </div>
                        <div className='right-side'>
                            <h2>Associar Usuários:</h2>
                            <label htmlFor='users_report_add'>Selecionar Usuário:</label>
                            <select id='users_report_add' value={selectedUser} onChange={handleSelectUser}>
                                <option value="" disabled>Selecione um usuário</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                            <div className='add-user' onClick={handleAddUser}>
                                <img src={props.images.addImage} alt="Adicionar" />
                                <span>Adicionar</span>
                            </div>
                            <div className='selected-users'>
                                <h3>Usuários Selecionados:</h3>
                                <ul>
                                    {selectedUsers.map(user => (
                                        <li key={user.id}>- {user.name}
                                            <img src={props.images.userFiltersImage} onClick={() => handleOpenUpdateFilters(user.id, user.name)} />
                                            <img src={excluir} onClick={() => handleDeleteSelectedUser(user.id)} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <button className='criar-report' type='submit'>Criar Relatório</button>
                    </form>
                )}
            </div>
            ) : (
                <DatasetFilter infosDataset={infosDatasetFilters} setSelectedUsers={setSelectedUsers} setIsDatasetFiltersOpened={setIsDatasetFiltersOpened} images={props.images} />
            )}
        </>
    )
}