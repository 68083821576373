import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import './perfilAdm.scss';

import voltar from '../../../assets/img/voltar.png';


export default function PerfilAdm(props) {

    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    async function handleSubmitNewPassword(e) {
        e.preventDefault();
        if (!oldPassword || !newPassword) {
            swal('Campos faltantes!', 'Por favor, preencha todos os campos', 'warning');
            return;
        }
    
        // Verificação de complexidade da nova senha
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
        if (!passwordRegex.test(newPassword) || newPassword.length < 6) {
            swal('Senha fraca!', 'A nova senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula e um número.', 'warning');
            return;
        }
    
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }
    
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
    
        try {
            const response = await axios.post(`${uri}/updatePasswordAdm`, { old_password: oldPassword, new_password: newPassword }, config);
            if (response.data.success) {
                swal('Sucesso!', 'Senha alterada com sucesso!', 'success')
                navigate('/gerenciamento');
            } else {
                swal('Erro!', response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
        }
    }

    return(
        <>
            <h1>Mudar Senha</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')} />
            </div>
            <div className='default-list'>
                <form onSubmit={(e) => handleSubmitNewPassword(e)} className='form-pass'>
                    <label htmlFor='pass-perfil'>Senha Atual:</label>
                    <input onChange={(e) => setOldPassword(e.target.value)} type='password' name='pass-perfil' id='pass-perfil' />
                    <label htmlFor='new-pass-perfil'>Nova Senha:</label>
                    <input onChange={(e) => setNewPassword(e.target.value)} type='password' name='new-pass-perfil' id='new-pass-perfil' />
                    <span>A senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula e um número.</span>
                    <button type='submit'>Mudar Senha</button>
                </form>
            </div>
        </>
    )
}