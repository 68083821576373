import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import { uri } from '../../configs_client';
import './powerBiReport.scss';

const PowerBIReportPlaylist = ({ fpHash = '', id_playlist = 0, width, height, borderRadius = false }) => {
  const reportContainerRef = useRef(null);
  const [powerBILoaded, setPowerBILoaded] = useState(false);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const timeoutRef = useRef(null); // Referência para armazenar o ID do timeout

  function getJwtToken() {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
    return token;
  }

  useEffect(() => {
    async function getReports() {
      try {
        const token = getJwtToken();

        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const response = await axios.post(`${uri}/getPlaylistReportsRun`, { fingerprint: fpHash, id_playlist: id_playlist }, config);

        if (response.data.success) {
          setReports(response.data.reports);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
        navigate('/');
      }
    }
    if(fpHash){
      getReports();
    }
  }, [fpHash, id_playlist, navigate]);

  useEffect(() => {
    const loadPowerBIScript = () => {
      const script = document.createElement('script');
      // script.src = 'https://cdn.jsdelivr.net/npm/powerbi-client@2.18.6/dist/powerbi.js';
      script.src = 'https://platform.soaresconsultingdata.com/powerbi-client/powerbi.js';
      script.async = true;
      script.onload = () => {
        setPowerBILoaded(true);
      };
      script.onerror = () => {
        swal('Erro', 'Erro ao carregar relatório', 'error');
      };
      document.body.appendChild(script);
    };
  
    if (!window.powerbi) {
      loadPowerBIScript();
    } else {
      setPowerBILoaded(true);
    }
  
    return () => {
      if (window.powerbi && reportContainerRef.current) {
        window.powerbi.reset(reportContainerRef.current);
      }
    };
  }, []);
  

  useEffect(() => {
    const embedReport = async () => {
      try {
        if (reports.length === 0) {
          return; // Se não houver relatórios, não faz nada
        }

        const currentReport = reports[currentReportIndex];
        const models = window['powerbi-client'].models;

        // Preparar os filtros recebidos do backend
        let filters = [];
        if (currentReport.filters) {
          // Agrupar filtros por table e column
          const groupedFilters = currentReport.filters.reduce((acc, filter) => {
            const key = `${filter.table}:${filter.column}:${filter.condition}`;
            if (!acc[key]) {
              acc[key] = {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                  table: filter.table,
                  column: filter.column
                },
                operator: filter.condition,
                values: []
              };
            }
            if(filter.data_type === 'number') {
              filter.value = Number(filter.value);
            }
            acc[key].values.push(filter.value);
            return acc;
          }, {});

          // Converter objetos agrupados em array
          filters = Object.values(groupedFilters);
        }

        const embedConfig = {
          type: 'report',
          id: currentReport.link_report,
          embedUrl: `https://app.powerbi.com/reportEmbed?navContentPaneEnabled=true`,
          accessToken: currentReport.token,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
            },
          },
          filters: filters.length > 0 ? filters : []
        };

        if (reportContainerRef.current && window.powerbi) {
          try {
            const report = window.powerbi.embed(reportContainerRef.current, embedConfig);

            report.on('loaded', async () => {
              const pages = await report.getPages(); // Obtemos todas as páginas do relatório

              if (pages.length === 0) {
                swal('Erro', 'Nenhuma página encontrada no relatório', 'error');
                return;
              }

              let currentPageIndex = 0;

              const showPage = async () => {
                const navigationEnabled = currentReport.navegacao === 'S'; // Verifica a navegação
                const screenTime = currentReport.tempo_atualizacao ?? 30000; // Tempo para troca de página

                if (navigationEnabled) {
                  // Se a navegação for 'S', trocamos de relatório em vez de página
                  if (currentPageIndex < pages.length) {
                    await report.setPage(pages[currentPageIndex].name); // Navega para a página atual
                    currentPageIndex = pages.length;
                  } else {
                    // Se todas as páginas foram exibidas, trocamos de relatório
                    if (currentReportIndex < reports.length - 1) {
                      setCurrentReportIndex(currentReportIndex + 1);
                      setCurrentScreenIndex(0);
                    } else {
                      // Voltar para o primeiro relatório
                      setCurrentReportIndex(0);
                      setCurrentScreenIndex(0);
                    }
                    return;
                  }
                } else {
                  // Se a navegação for 'N', avançamos entre páginas
                  if (currentPageIndex < pages.length) {
                    await report.setPage(pages[currentPageIndex].name); // Navega para a página atual
                    currentPageIndex++;
                  } else {
                    // Se todas as páginas foram exibidas, trocamos de relatório
                    if (currentReportIndex < reports.length - 1) {
                      setCurrentReportIndex(currentReportIndex + 1);
                      setCurrentScreenIndex(0);
                    } else {
                      // Voltar para o primeiro relatório
                      setCurrentReportIndex(0);
                      setCurrentScreenIndex(0);
                    }
                    return;
                  }
                }

                // Limpa o timeout anterior, se existir
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }

                timeoutRef.current = setTimeout(showPage, screenTime);
              };

              showPage();
            });
          } catch (error) {
            swal('Erro', 'Erro ao carregar relatório', 'error');
          }
        }
      } catch (error) {
        swal('Erro', 'Erro ao carregar relatório', 'error');
      }
    };

    if (powerBILoaded && fpHash) {
      embedReport();
    }

    return () => {
      // Limpa o timeout quando o componente é desmontado ou quando o relatório é alterado
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [powerBILoaded, reports, currentReportIndex]);

  return (
    <div
      id='reportContainer'
      ref={reportContainerRef}
      className={borderRadius ? 'bdRadius' : ''}
      style={{ width: width, height: height }}
    ></div>
  );
};

export default PowerBIReportPlaylist;


// ABAIXO CONSIDERANDO OS FILTROS, TESTAR PARA O PRÓXIMO COMMIT

// import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// import { uri } from '../../configs_client';
// import './powerBiReport.scss';

// const PowerBIReportPlaylist = ({ fpHash = '', id_playlist = 0, width, height, borderRadius = false }) => {
//   const reportContainerRef = useRef(null);
//   const [powerBILoaded, setPowerBILoaded] = useState(false);
//   const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
//   const [currentReportIndex, setCurrentReportIndex] = useState(0);
//   const [reportList, setReportList] = useState([]);
//   const [reports, setReports] = useState([]);
//   const navigate = useNavigate();
//   const timeoutRef = useRef(null); // Referência para armazenar o ID do timeout

//   function getJwtToken() {
//     const token = sessionStorage.getItem('token');
//     if (!token) {
//       navigate('/');
//     }
//     return token;
//   }

//   useEffect(() => {
//     async function getReports() {
//       try {
//         const token = getJwtToken();

//         const config = {
//           headers: { Authorization: `Bearer ${token}` }
//         };

//         const response = await axios.post(`${uri}/getPlaylistReportsRun`, { fingerprint: fpHash, id_playlist: id_playlist }, config);

//         if (response.data.success) {
//           setReports(response.data.reports);
//         } else {
//           navigate('/');
//         }
//       } catch (error) {
//         console.error('Error fetching reports:', error);
//         navigate('/');
//       }
//     }
//     if(fpHash){
//       getReports();
//     }
//   }, [fpHash, id_playlist, navigate]);

//   useEffect(() => {
//     const loadPowerBIScript = () => {
//       const script = document.createElement('script');
//       script.src = 'https://microsoft.github.io/PowerBI-JavaScript/demo/node_modules/powerbi-client/dist/powerbi.js';
//       script.async = true;
//       script.onload = () => {
//         console.log('Power BI script loaded successfully.');
//         setPowerBILoaded(true);
//       };
//       script.onerror = () => {
//         console.error('Error loading Power BI script');
//       };
//       document.body.appendChild(script);
//     };

//     if (!window.powerbi) {
//       loadPowerBIScript();
//     } else {
//       console.log('Power BI script already loaded.');
//       setPowerBILoaded(true);
//     }

//     return () => {
//       if (window.powerbi && reportContainerRef.current) {
//         window.powerbi.reset(reportContainerRef.current);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const embedReport = async () => {
//       try {
//         if (reports.length === 0) {
//           return; // Se não houver relatórios, não faz nada
//         }

//         const currentReport = reports[currentReportIndex];
//         const models = window['powerbi-client'].models;

//         // Preparar os filtros recebidos do backend
//         let filters = [];
//         if (currentReport.filters) {
//           // Agrupar filtros por table e column
//           const groupedFilters = currentReport.filters.reduce((acc, filter) => {
//             const key = `${filter.table}:${filter.column}:${filter.condition}`;
//             if (!acc[key]) {
//               acc[key] = {
//                 $schema: 'http://powerbi.com/product/schema#basic',
//                 target: {
//                   table: filter.table,
//                   column: filter.column
//                 },
//                 operator: filter.condition,
//                 values: []
//               };
//             }
//             if (filter.data_type === 'number') {
//               filter.value = Number(filter.value);
//             }
//             acc[key].values.push(filter.value);
//             return acc;
//           }, {});

//           // Converter objetos agrupados em array
//           filters = Object.values(groupedFilters);
//         }

//         const embedConfig = {
//           type: 'report',
//           id: currentReport.link_report,
//           embedUrl: `https://app.powerbi.com/reportEmbed?navContentPaneEnabled=true`,
//           accessToken: currentReport.token,
//           settings: {
//             panes: {
//               filters: {
//                 visible: false,
//               },
//             },
//           },
//           filters: filters.length > 0 ? filters : []
//         };

//         if (reportContainerRef.current && window.powerbi) {
//           try {
//             const report = window.powerbi.embed(reportContainerRef.current, embedConfig);

//             report.on('loaded', async () => {
//               const pages = await report.getPages(); // Obtemos todas as páginas do relatório

//               if (pages.length === 0) {
//                 console.error('No pages found in the report');
//                 return;
//               }

//               let currentPageIndex = 0;

//               const showPage = async () => {
//                 console.log(currentReport)
//                 const navigationEnabled = currentReport.navegacao === 'S'; // Verifica a navegação
//                 const screenTime = currentReport.tempo_atualizacao ?? 30000; // Tempo para troca de página

//                 if (navigationEnabled) {
//                   // Se a navegação for 'S', trocamos de relatório em vez de página
//                   if (currentPageIndex < pages.length) {
//                     await report.setPage(pages[currentPageIndex].name); // Navega para a página atual
//                     currentPageIndex = pages.length;
//                   } else {
//                     // Se todas as páginas foram exibidas, trocamos de relatório
//                     if (currentReportIndex < reports.length - 1) {
//                       setCurrentReportIndex(currentReportIndex + 1);
//                       setCurrentScreenIndex(0);
//                     } else {
//                       // Voltar para o primeiro relatório
//                       setCurrentReportIndex(0);
//                       setCurrentScreenIndex(0);
//                     }
//                     return;
//                   }
//                 } else {
//                   // Se a navegação for 'N', avançamos entre páginas
//                   if (currentPageIndex < pages.length) {
//                     await report.setPage(pages[currentPageIndex].name); // Navega para a página atual
//                     currentPageIndex++;
//                   } else {
//                     // Se todas as páginas foram exibidas, trocamos de relatório
//                     if (currentReportIndex < reports.length - 1) {
//                       setCurrentReportIndex(currentReportIndex + 1);
//                       setCurrentScreenIndex(0);
//                     } else {
//                       // Voltar para o primeiro relatório
//                       setCurrentReportIndex(0);
//                       setCurrentScreenIndex(0);
//                     }
//                     return;
//                   }
//                 }

//                 // Limpa o timeout anterior, se existir
//                 if (timeoutRef.current) {
//                   clearTimeout(timeoutRef.current);
//                 }

//                 timeoutRef.current = setTimeout(showPage, screenTime);
//               };

//               showPage();
//             });
//           } catch (error) {
//             console.error('Error embedding Power BI report', error);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching reports or embedding report:', error);
//       }
//     };

//     if (powerBILoaded && fpHash) {
//       embedReport();
//     }

//     return () => {
//       // Limpa o timeout quando o componente é desmontado ou quando o relatório é alterado
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     };
//   }, [powerBILoaded, reports, currentReportIndex]);

//   return (
//     <div
//       id='reportContainer'
//       ref={reportContainerRef}
//       className={borderRadius ? 'bdRadius' : ''}
//       style={{ width: width, height: height }}
//     ></div>
//   );
// };

// export default PowerBIReportPlaylist;
