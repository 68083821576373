import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import home from '../../../assets/img/home.png';
import voltar from '../../../assets/img/voltar.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import logout from '../../../assets/img/logout.png';
import './grupoReports.scss';

export default function GrupoReports({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const { id_grupo, name_group } = useParams();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchReportsAndImages() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getUserGroupReports`, { fingerprint: fpHash, id_group: id_grupo }, config);

                if (response.data.success) {
                    const reportsData = response.data.reports;

                    const updatedReports = await Promise.all(
                        reportsData.map(async (report) => {
                            const imageUrl = await isImageValid(`${uri}/${report.path_img}`);
                            return { ...report, imageUrl };
                        })
                    );

                    setReports(updatedReports);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        if (fpHash) {
            fetchReportsAndImages();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }

    const handleOpenReport = (id_report) => {
        navigate(`/relatorio/${id_report}`);
    };

    async function isImageValid(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(url);
            img.onerror = () => resolve(`${uri}/assets/reports/default.png`);
            img.src = url;
        });
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredReports = reports.filter(report => 
        report.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                        
                    
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                     
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                        
                </div>
                <div className='relatorios'>
                   
                    <div className='relatorios-card'>
                        <h1>{name_group}</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate(`/home`)} />
                        </div>
                        <div className='search-bar'>
                            <input 
                                type="text" 
                                placeholder="Pesquisar relatórios..." 
                                value={search} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                        <div className='relatorios-list'>
                            {loading ? (
                                <div className="loading-spinner textReport">
                                    <ClipLoader color="#000000" loading={loading} size={50} /> 
                                </div>
                            ) : filteredReports.length > 0 ? (
                                filteredReports.map((report, index) => (
                                    <div className='group-relatorio' onClick={() => handleOpenReport(report.id)} key={index}>
                                        <img src={report.imageUrl} className='report-img' alt={report.name} />
                                        <span>{report.name}</span>
                                    </div>
                                ))
                            ) : (
                                <span className='textReport'>Nenhum relatório disponível</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}