import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { uri } from '../../../configs_client';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';

import playlistMenu from '../../../assets/img/playlist-menu.png';
import voltar from '../../../assets/img/voltar.png';
import perfil from '../../../assets/img/perfil.png';
import telaCheia from '../../../assets/img/tela_cheia.png';
import logout from '../../../assets/img/logout.png';
import home from '../../../assets/img/home.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import './editarPlaylistReport.scss';

export default function EditarPlaylistReport({ fpHash, expanded, expRelatorio, containerRef, images, texts }) {
    const navigate = useNavigate();
    const { id_playlist, id_playlist_report } = useParams();
    const [tempoAtualizacao, setTempoAtualizacao] = useState('');
    const [selectedReport, setSelectedReport] = useState('');
    const [selectedNavegacao, setSelectedNavegacao] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        async function fetchReports() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getUserReports`, { fingerprint: fpHash }, config);

                if (response.data.success) {
                    const reports = response.data.reports;

                    setReports(reports);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            }
        }

        async function fetchPlaylistReport() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/getPlaylistReport`, { fingerprint: fpHash, id_playlist_report: id_playlist_report, id_playlist: id_playlist }, config);

                if (response.data.success) {
                    const playlist_report = response.data.playlist_report;

                    setTempoAtualizacao(playlist_report.tempo_atualizacao);
                    setSelectedReport(playlist_report.id);
                    setSelectedNavegacao(playlist_report.navegacao);
                } else {
                    navigate('/');
                }
            } catch (error) {
                swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
                navigate('/');
            } finally {
                setIsLoading(false);
            }
        }

        if (fpHash) {
            fetchReports();
            fetchPlaylistReport();
        }
    }, [fpHash, navigate]);

    const userLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/');
    };

    const handleRedirectPlaylists = () => {
        navigate('/playlists');
    }

    const handleRedirectHome = () => {
        navigate('/home');
    }

    const handleRedirectRelatorios = () => {
        navigate('/relatorios');
    }
    
    const handleEditPlaylistReport = async (e) => {
        e.preventDefault();

        if (tempoAtualizacao === '') {
            swal('Erro', 'O tempo de atualização da playlist não pode ser vazio', 'error');
            return;
        } else if (selectedReport === '') {
            swal('Erro', 'Selecione um relatório', 'error');
            return;
        } else if(selectedNavegacao === '') {
            swal('Erro', 'Selecione uma opção de navegação', 'error');
            return;
        } else if(isNaN(tempoAtualizacao)) {
            swal('Erro', 'O tempo de atualização precisa ser um número!', 'error');
            return;
        }

        let tempoAtualizacaoRounded = Math.round(tempoAtualizacao);

        if(tempoAtualizacaoRounded < 1) {
            swal('Erro', 'O tempo de atualização da playlist não pode ser menor que 1 segundo', 'error');
            return;
        }

        try {
            const token = sessionStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.post(`${uri}/updatePlaylistReport`, {
                id_playlist: id_playlist,
                id_playlist_report: id_playlist_report,
                tempo_atualizacao: tempoAtualizacaoRounded,
                id: selectedReport,
                navegacao: selectedNavegacao,
                fingerprint: fpHash
            }, config);

            if (response.status === 200) {
                swal('Sucesso', 'Relatório atualizado com sucesso', 'success');
                navigate(`/playlist/${id_playlist}`);
            }
        } catch (error) {
            swal('Erro', error.response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
        }
    }

    const handlePlaylistReport = (e) => {
        setSelectedReport(e.target.value);
    }

    const handleNavegacao = (e) => {
        setSelectedNavegacao(e.target.value);
    }
    
    const handleDeletePlaylistReport = async () => {
        swal({
            title: "Deseja excluir o relatório?",
            text: `O relatório será excluído da playlist!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                try {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                        navigate('/');
                        return;
                    }

                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };

                    const response = await axios.post(`${uri}/deletePlaylistReport`, {id_playlist: id_playlist, id_playlist_report: id_playlist_report, fingerprint: fpHash}, config);

                    if (response.data.success) {
                        navigate(`/playlist/${id_playlist}`);
                    } else {
                        swal("Erro ao excluir", "Ocorreu um erro ao excluir a playlist", "error");
                    }
                } catch (error) {
                    swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error")
                }
            }
        });
    }

    return (
        <section ref={containerRef} className='relatorio'>
            {images.fundoRelatorios ? 
                <img className="backgroundRelatorio" src={images.fundoRelatorios} alt="Background" /> 
            : null}
            <div className="page">
                <div className="menuTop">
                    <img className='telaCheia' onClick={expRelatorio} src={telaCheia} alt="Tela Cheia" />
                    <img className='perfil' onClick={() => navigate('/perfil')} src={perfil} alt="Perfil" />
                </div>
                <div className="menuLeft">
                    <div className="tooltip">
                        <img onClick={handleRedirectHome} className="home" src={home} alt="Playlists" />
                        <span className="tooltiptext">Home</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectRelatorios} className="relatorios-menu" src={relatorios} alt="Playlists" />
                        <span className="tooltiptext">Relatórios (Geral)</span>
                    </div>
                    <div className="tooltip">
                        <img onClick={handleRedirectPlaylists} className="playlists" src={playlistMenu} alt="Playlists" />
                        <span className="tooltiptext">Ver Playlists</span>
                    </div>
                    
                    <img onClick={handleRedirectHome} className="logoRelatorio" src={images.logoRelatorios} alt="Logo" />
                
                    <img className='logout' onClick={userLogout} src={logout} alt='logout' />
                </div>
                <div className='add-playlist-container'>
                    <div className='add-playlist-card'>
                        <h1>Editar Relatório - Playlist</h1>
                        <div className='page-actions'>
                            <img src={images.voltarImage} alt='Voltar' onClick={() => navigate(`/playlist/${id_playlist}`)} />
                        </div>
                        <div className='add-playlist-container-form'>
                            {isLoading ? 
                                <div className="loading-spinner textReport">
                                    <ClipLoader color="#000000" loading={isLoading} size={50} /> 
                                </div>
                            : (
                                <form onSubmit={(e) => handleEditPlaylistReport(e)} className='form-add-playlist'>
                                    <label htmlFor='add-playlist-input' className='add-playlist-label-input-first'>Relatório:</label>
                                    <select value={selectedReport} id='playlist_report' onChange={(e) => handlePlaylistReport(e)}>
                                        <option value="" disabled>Selecione um relatório</option>
                                        {reports.map((report, index) => (
                                        <option key={index} value={report.id_user_report}>
                                            {report.name}
                                        </option>
                                        ))}
                                    </select>
                                    <label htmlFor='add-playlist-input'>Tempo de atualização (segundos):</label>
                                    <input value={tempoAtualizacao} placeholder='Tempo de atualização...' onChange={(e) => setTempoAtualizacao(e.target.value)} type='number' name='add-playlist-input' id='add-playlist-input' />
                                    <label htmlFor='add-playlist-input'>Relatório Navegável?</label>
                                    <select value={selectedNavegacao} id='playlist_report' onChange={(e) => handleNavegacao(e)}>
                                        <option value="" disabled>Selecione se é navegável</option>
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                    <button className='button-playlist-report' type='submit'>Salvar Alterações</button>
                                    <button className='excluir-playlist' type='button' onClick={handleDeletePlaylistReport}>Excluir</button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}