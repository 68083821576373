import { setup, uri } from "../../configs_client";

export const images = {
    fundoLogin: `${uri}/assets/site/setup_${setup}/fundo_login.png?${new Date().getTime()}`,
    mainImageLogin: `${uri}/assets/site/setup_${setup}/main_image_login.png?${new Date().getTime()}`,
    logoLogin: `${uri}/assets/site/setup_${setup}/logo_login.png?${new Date().getTime()}`,

    // fundoRelatorios: `${uri}/assets/site/setup_${setup}/fundo_relatorio.png`,
    logoRelatorios: `${uri}/assets/site/setup_${setup}/logo_relatorio.png?${new Date().getTime()}`,
    addImage : `${uri}/assets/site/setup_${setup}/add.png?${new Date().getTime()}`,
    editImage : `${uri}/assets/site/setup_${setup}/editar.png?${new Date().getTime()}`,
    editGroup: `${uri}/assets/site/setup_${setup}/editar-grupo.png?${new Date().getTime()}`,
    deleteGroup: `${uri}/assets/site/setup_${setup}/excluir-grupo.png?${new Date().getTime()}`,
    playlistImage: `${uri}/assets/site/setup_${setup}/playlist.png?${new Date().getTime()}`,
    userFiltersImage: `${uri}/assets/site/setup_${setup}/user_filters.png?${new Date().getTime()}`,
    voltarImage: `${uri}/assets/site/setup_${setup}/voltar.png?${new Date().getTime()}`,
};

export const texts = {
    slogan: "Inteligência para Provedores.",
    suporteEmail: "suporte@soaresconsulting.com.br",
}