import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import swal from 'sweetalert';

import './login.scss';
import ReCaptchaV2 from 'react-google-recaptcha';
import { id_empresa, setup, uri } from '../../configs_client';

export default function Login({images, texts}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');
    const [fpHash, setFpHash] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const loadFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            setFpHash(visitorId);
        };
        loadFingerprint();
    }, []);

    function handleEmail(event) {
        const email = event.target.value;
        setEmail(email);
    }

    function handlePassword(event) {
        const password = event.target.value;
        setPassword(password);
    }

    async function verifyLogin() {
        if (!email || !password) {
            swal ( "Campos vazios" ,  "Por favor, preencha os campos de email e senha!" ,  "error" )
            return false;
        }
        if (!captchaToken) {
            swal ( "Captcha inválido" ,  "Por favor, preencha o captcha!" ,  "error" )
            return false;
        }
        return true;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const loginVerify = await verifyLogin();
        if(!loginVerify) return;

        try {
            const response = await axios.post(`${uri}/login`, {
                email: email,
                password: password,
                id_empresa: id_empresa,
                captcha_token: captchaToken,
                id_setup: setup,
                fingerprint: fpHash // Enviar o fingerprint junto com as credenciais
            });

            if (response.data.success) {
                sessionStorage.setItem('token', response.data.token);
                const decodedToken = parseJwt(response.data.token);
        
                if (decodedToken.adm === true) {
                    navigate('/gerenciamento'); 
                } else {
                    navigate('/home'); 
                }
            } else {
                swal ("Houve um erro ao logar." ,  "Ocorreu um erro ao logar, tente novamente" ,  "error")
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                swal ("Não autorizado." ,  "Email ou senha incorretos!" ,  "error")
            } else if(error.response && error.response.status === 422) {
                swal ("Não autorizado." ,  "Captcha inválido!" ,  "error")
            } else {
                swal ("Houve um erro ao logar." ,  "Ocorreu um erro ao logar, tente novamente" ,  "error")
            }
        }
    }

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const handleToken = (token) => {
        setCaptchaToken(token);
    }

    const handleExpire = () => {
        setCaptchaToken(null);
    }

    return (
        <section className='loginPage'>
            {images.fundoLogin ? 
                <img className="backgroundLogin" src={images.fundoLogin} />    
            : null}
            {images.logoLogin ?
            <img className="logo" src={images.logoLogin} alt="Logo" />
             : null}
            <div className='cardPrototipo'>
                <span>{texts.slogan}</span>
                {images.mainImageLogin ? 
                    <img src={images.mainImageLogin} alt="Main Image" /> 
                : null}
            </div>
            <div className='cardLogin'>
                <h1>LOGIN</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email:</label>
                    <input onChange={handleEmail} type="email" id="email" name="email" required />
                    <label htmlFor="password">Senha:</label>
                    <input onChange={handlePassword} type="password" id="password" name="password" required />
                    <ReCaptchaV2 
                        sitekey="6LfDP_kpAAAAAEQeyBMoaHtgeSxs0y2TKqpc4n4i"
                        onChange={handleToken}
                        onExpired={handleExpire}
                    />
                    <button type="submit">Entrar</button>
                </form>
            </div>
        </section>
    );
}