import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { setup, uri } from '../../../configs_client';
import voltar from '../../../assets/img/voltar.png';
import './adicionarUsuario.scss';

export default function AdicionarUsuario(props) {

    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    async function handleAddUser(event) {
        event.preventDefault();
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }

        if (!user.name || !user.email || !user.password) {
            swal('Campos faltantes!', 'Por favor, preencha todos os campos', 'warning');
            return;
        } else if(user.password.length < 6) {
            swal('Senha muito curta!', 'A senha deve ter no mínimo 6 caracteres', 'warning');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        user.id_setup = setup;

        try {
            const response = await axios.post(`${uri}/insertUser`, user, config);
            if (response.data.success) {
                navigate('/usuarios');
            } else {
                swal('Erro!', response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente", "error");
        }
    }

    function handleUser(event, field) {
        const { value } = event.target;
        setUser({ ...user, [field]: value });
    }

    return(
        <>
            <h1>Adicionar Usuário</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/usuarios')}/>
            </div>
            <div className='default-list'>
                {loading ? (
                        <div className="loading-spinner textReport">
                            <ClipLoader color="#000000" loading={loading} size={50} /> 
                        </div>
                    ) : (
                    <div className='card-usuario'>
                        <form onSubmit={handleAddUser}>
                            <div className='inputs'>
                                <div className='left-form'>
                                    <label htmlFor='name_user_add'>Nome:</label>
                                    <input id='name_user_add' onChange={(e) => handleUser(e, 'name')} type='text' name='name' />
                                    <label htmlFor='email_user_add'>Email:</label>
                                    <input id='email_user_add' onChange={(e) => handleUser(e, 'email')} type='email' name='email' />
                                </div>
                                <div className='right-form'>
                                    <label htmlFor='password_user_add'>Senha:</label>
                                    <input id='password_user_add' className='password-campo'onChange={(e) => handleUser(e, 'password')} type='password' name='password' />
                                    <span>A senha poderá ser alterada pelo usuário posteriormente</span>
                                </div>
                            </div>
                            <button type='submit'>Criar Usuário</button>
                        </form>
                    </div>
                )}
            </div>
        </>
    )
}