import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { uri } from '../../configs_client';
import './powerBiReport.scss';

const PowerBIReport = ({ gerencial = false, fpHash = '', id_report = 0, width, height, borderRadius = false }) => {
  const reportContainerRef = useRef(null);
  const [powerBILoaded, setPowerBILoaded] = useState(false);
  const navigate = useNavigate();

  function getJwtToken() {
    const token = sessionStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
    return token;
  }

  async function getReport() {
    try {
      const token = getJwtToken();

      // Define o cabeçalho de autorização com o token JWT
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const response = await axios.post(`${uri}/getReport`, { fingerprint: fpHash, id_report: id_report, gerencial: gerencial }, config);

      if (response.data.success) {
        return response.data.report;
      } else {
        navigate('/');
        return null; // Retorna null em caso de erro ou sucesso sem token
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
      navigate('/');
      return null; // Retorna null em caso de erro ou sucesso sem token
    }
  }

  useEffect(() => {
    const loadPowerBIScript = () => {
      const script = document.createElement('script');
      // script.src = 'https://cdn.jsdelivr.net/npm/powerbi-client@2.18.6/dist/powerbi.js';
      script.src = 'https://platform.soaresconsultingdata.com/powerbi-client/powerbi.js';
      script.async = true;
      script.onload = () => {
        setPowerBILoaded(true);
      };
      script.onerror = () => {
        console.error('Error loading Power BI script');
      };
      document.body.appendChild(script);
    };

    if (!window.powerbi) {
      loadPowerBIScript();
    } else {
      setPowerBILoaded(true);
    }

    return () => {
      // Verifica se window.powerbi existe antes de chamar reset
      if (window.powerbi && reportContainerRef.current) {
        window.powerbi.reset(reportContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const embedReport = async () => {
      try {
        const reportInfos = await getReport(); // Espera a resolução da promessa

        if (reportInfos.link_report === 0) {
          navigate('/relatorios');
        }

        const models = window['powerbi-client'].models;

        // Preparar os filtros recebidos do backend
        let filters = [];
        if (reportInfos.filters) {
          // Agrupar filtros por table e column
          const groupedFilters = reportInfos.filters.reduce((acc, filter) => {
            const key = `${filter.table}:${filter.column}:${filter.condition}`;
            if (!acc[key]) {
              acc[key] = {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                  table: filter.table,
                  column: filter.column
                },
                operator: filter.condition,
                values: []
              };
            }
            if(filter.data_type === 'number') {
              filter.value = Number(filter.value);
            }
            acc[key].values.push(filter.value);
            return acc;
          }, {});

          // Converter objetos agrupados em array
          filters = Object.values(groupedFilters);
        }

        const embedConfig = {
          type: 'report',
          id: reportInfos.link_report, // ID do relatório Power BI
          embedUrl: `https://app.powerbi.com/reportEmbed?navContentPaneEnabled=true`,
          accessToken: reportInfos.token,
          settings: {
            panes: {
              filters: {
                visible: false, // Oculta os filtros do lado
              },
            },
          },
          // Adicione os filtros aqui, se houver
          filters: filters.length > 0 ? filters : []
        };
        
        if (reportContainerRef.current && window.powerbi) {
          try {
            const report = window.powerbi.embed(reportContainerRef.current, embedConfig);

            // Aplique os filtros ao relatório após a carga
            report.on('loaded', () => {
              if (filters.length > 0) {
                report.updateFilters(models.FiltersOperations.Replace, filters)
                  .then(() => {
                    console.log('Filtros aplicados com sucesso');
                  })
                  .catch(errors => {
                    console.error(errors);
                  });
              }
            });
          } catch (error) {
            console.error('Error embedding Power BI report', error);
          }
        }
      } catch (error) {
        console.error('Error fetching access token or embedding report:', error);
      }
    };

    if (powerBILoaded && fpHash) {
      embedReport();
    }
  }, [powerBILoaded, fpHash]);

  return (
    <div
      id='reportContainer'
      ref={reportContainerRef}
      className={borderRadius ? 'bdRadius' : ''}
      style={{ width: width, height: height }}
    ></div>
  );
};

export default PowerBIReport;
