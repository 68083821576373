import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { id_empresa, uri } from '../../../configs_client';
import editar from '../../../assets/img/editar.png';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';
import './suporte.scss';

export default function Suporte(props) {
    const navigate = useNavigate();
    const [isSending, setIsSending] = useState(false); // Estado para controlar o envio

    const handleSubmitSuporte = (e) => {
        e.preventDefault();
        setIsSending(true); // Inicia o envio
        const form = e.target;
        const formData = new FormData(form);
        const data = {
            email: formData.get('email-suporte'),
            assunto: formData.get('assunto-suporte'),
            mensagem: formData.get('mensagem-suporte')
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
        axios.post(`${uri}/suporte`, data, { headers: headers })
        .then((response) => {
            if(response.data.success){
                swal('Sucesso', 'E-Mail enviado com sucesso!', 'success');
                form.reset();
            } else {
                swal('Erro', 'Erro ao enviar E-Mail, tente novamente!', 'error');
            }
            setIsSending(false); // Termina o envio
        })
        .catch((error) => {
            console.log(error)
            swal('Erro', error.response.data.message, 'error');
            setIsSending(false); // Termina o envio
        })
    }

    return(
        <>
            <h1>Suporte</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')}/>
            </div>
            <div className='suporte-container'>
                <span>Envie um E-Mail para suporte, sugestões, feedbacks, melhorias e etc. Resposta de chamado em até 2 horas.</span>
                
                {isSending ? (
                    <div className="sending-message">
                        <ClipLoader color="#000000" loading={isSending} size={50} /> {/* Bolinha de carregamento */}
                        <p>Enviando mensagem, por favor aguarde...</p>
                    </div>
                ) : (
                    <form className='form-suporte' onSubmit={handleSubmitSuporte}>
                        <label>Seu E-Mail (Coloque um E-Mail real para receber a resposta):</label><br/>
                        <input placeholder='Digite um E-Mail' name='email-suporte' id='email-suporte' required />
                        <label>Digite o assunto:</label><br/>
                        <input placeholder='Digite o Assunto' name='assunto-suporte' id='assunto-suporte' required />
                        <label>Digite a mensagem:</label><br/>
                        <textarea placeholder='Digite a mensagem' name='mensagem-suporte' id='mensagem-suporte' required />
                        <button type='submit'>Enviar Suporte</button>
                    </form>
                )}
            </div>
        </>
    )
}
