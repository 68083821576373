import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import swal from 'sweetalert';
import { uri } from '../../../configs_client';
import voltar from '../../../assets/img/voltar.png';
import './editarUsuario.scss';

export default function EditarUsuario(props) {

    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUser() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/user`, {id_user: props.id_user}, config);
                if (response.data.success) {
                    const userData = response.data.user;
                    setUser({id: props.id_user, name: userData.name, email: userData.email, password: ''})
                } else {
                    // navigate('/');
                }
            } catch (error) {
                console.error('Error fetching reports and images:', error);
                // navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchUser();
    }, [])
    


    async function handleUpdateUser(event) {
        event.preventDefault();
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }

        if(!user.name || !user.email) {
            swal('Campos faltantes!', 'Por favor, preencha todos os campos', 'warning');
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        try {
            const response = await axios.post(`${uri}/updateUser`, user, config);
            if (response.data.success) {
                navigate('/usuarios');
            } else {
                swal('Erro!', response.data?.error ?? "Ocorreu um erro, tente novamente", 'error');
            }
        } catch (error) {
            swal("Ocorreu um erro!", error.response?.data?.error ?? "Ocorreu um erro, tente novamente", "error");
        }
    }

    function handleUser(event, field) {
        const { value } = event.target;
        setUser({ ...user, [field]: value });
    }

    return(
        <>
            <h1>Editar Usuário</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/usuarios')}/>
            </div>
            <div className='default-list'>
                {loading ? (
                        <div className="loading-spinner textReport">
                            <ClipLoader color="#000000" loading={loading} size={50} /> 
                        </div>
                    ) : (
                    <div className='card-usuario'>
                        <form onSubmit={handleUpdateUser}>
                            <div className='inputs'>
                                <div className='left-form'>
                                    <label htmlFor='name_user'>Nome:</label>
                                    <input value={user.name} onChange={(e) => handleUser(e, 'name')} type='text' name='name' id='name_user' />
                                    <label htmlFor='email_user'>Email:</label>
                                    <input value={user.email} onChange={(e) => handleUser(e, 'email')} type='email' name='email' id="email_user" />
                                </div>
                                <div className='right-form'>
                                    <label htmlFor='password_user'>Senha:</label>
                                    <input value={user.password} className='password-campo'onChange={(e) => handleUser(e, 'password')} type='password' name='password' id='password_user' />
                                    <span>A senha poderá ser alterada pelo usuário posteriormente</span>
                                </div>
                            </div>
                            <button type='submit'>Salvar Alterações</button>
                        </form>
                    </div>
                )}
            </div>
        </>
    )
}