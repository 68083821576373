import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import { id_empresa, uri } from '../../../configs_client';
import editar from '../../../assets/img/editar.png';
import novo from '../../../assets/img/add.png';
import voltar from '../../../assets/img/voltar.png';
import excluir from '../../../assets/img/excluir.png';
import './relatoriosGerenciais.scss';

export default function RelatoriosGerenciais(props) {

    const navigate = useNavigate();
    const [groupReports, setGroupReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchGroupsReports() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const response = await axios.post(`${uri}/relatoriosGerenciais`, {}, config);
                if (response.data.success) {
                    if (response.data.reports.length === 0) {
                        setGroupReports([]);
                        return;
                    }
                    const groupsReportsData = response.data.reports;
                    
                    const updatedReports = await Promise.all(
                        groupsReportsData.map(async (report) => {
                            const imageUrl = await isImageValid(`${uri}/${report.path_img}`);
                            return { ...report, imageUrl };
                        })
                    );
                    
                    setGroupReports(updatedReports);
                } else {
                    navigate('/');

                }
            } catch (error) {
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchGroupsReports();
        
    }, []);

    async function isImageValid(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(url);
            img.onerror = () => resolve(`${uri}/assets/reports/default.png`);
            img.src = url;
        });
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredGroupReports = groupReports.filter(report => 
        report.name.toLowerCase().includes(search.toLowerCase())
    );

    return(
        <>
            <h1>Seus Relatórios Gerenciais</h1>
            <div className='page-actions'>
                <img src={props.images.voltarImage} alt='Voltar' onClick={() => navigate('/gerenciamento')}/>
            </div>
            <div className='search-bar'>
                <input 
                    type="text" 
                    placeholder="Pesquisar relatórios..." 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className='default-list'>
                {loading ? (
                    <div className="loading-spinner textReport">
                        <ClipLoader color="#000000" loading={loading} size={50} /> 
                    </div>
                ) : filteredGroupReports.length > 0 ? (
                    filteredGroupReports.map((report, index) => (
                        <div className='group-relatorio' key={index} onClick={() => navigate(`/visualizarRelatorio/${report.id}`)}>
                            <img src={report.imageUrl} className='report-img' alt={report.name} />
                            <span>{report.name}</span>
                        </div>
                    ))
                ) : (
                    <span className='textReport'>Nenhum relatório gerencial disponível</span>
                )}
            </div>
        </>
    )
}