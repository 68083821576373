import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners'; // Importando o ClipLoader

import novo from '../../../assets/img/add.png';
import { uri } from '../../../configs_client';
import editar from '../../../assets/img/editar-grupo.png';
import excluir from '../../../assets/img/excluir-grupo.png';
import relatorios from '../../../assets/img/relatorios-menu.png';
import './gerenciamento.scss';

export default function Gerenciamento(props) {

    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        async function fetchGroups() {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    navigate('/');
                    return;
                }

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                const response = await axios.post(`${uri}/groups`, {}, config);

                if (response.data.success) {
                    const groupsData = response.data.groups;

                    setGroups(groupsData);
                } else {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            } finally {
                setLoading(false);
            }
        }

        fetchGroups();
        
    }, []);

    function handleOpenGroup(id, name) {
        navigate(`/grupoRelatorios/${id}/${name}`);
    }
    
    const handleDeleteGroup = async (id) => {
        const group = groups.find(group => group.id === id);
        const groupName = group.name;
        swal({
            title: "Deseja excluir o grupo?",
            text: `O grupo: ${groupName}, será excluído permanentemente!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                try {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                        navigate('/');
                        return;
                    }
        
                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };
        
                    const response = await axios.post(`${uri}/deleteGroup`, { id_group: id }, config);
        
                    if (response.data.success) {
                        setGroups(prevGroups => prevGroups.filter(group => group.id !== id));
                        swal ( "Grupo excluído!" ,  "Grupo excluído com sucesso!" ,  "success" )
                    } 
                } catch (error) {
                    swal("Ocorreu um erro!", error.response.data?.error ?? "Ocorreu um erro, tente novamente.", "error")
                }
            }
          });
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const filteredGroups = groups.filter(group => 
        group.name.toLowerCase().includes(search.toLowerCase())
    );

    return(
        <>
            <h1>Gerenciamento</h1>
            <div className='page-actions'>
                <img src={props.images.addImage} onClick={() => navigate('/adicionarGrupo/')} alt='Novo'/>
            </div>
            <div className='search-bar'>
                <input 
                    type="text" 
                    placeholder="Pesquisar grupos..." 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className='default-list'>
            {loading ? (
                <div className="loading-spinner textReport">
                     <ClipLoader color="#000000" loading={loading} size={50} /> 
                </div>
            ) : (
                filteredGroups.length > 0 ? (
                    filteredGroups.map((group, index) => (
                        <div className='group' key={index}>
                            <div onClick={() => handleOpenGroup(group.id, group.name)} className='group-card'>
                                <img className='img-group-report' src={relatorios}/>
                                <h2>{group.name}</h2>
                            </div>
                            <div className='actionsGroup actionsGroupGerenciamento'>
                                <img src={props.images.editGroup} onClick={() => navigate(`/editarGrupo/${group.id}/${group.name}`)} alt='Editar'/>
                                <img src={props.images.deleteGroup} onClick={() => handleDeleteGroup(group.id)} alt='Excluir'/>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="loading-spinner textReport">Nenhum grupo encontrado</div>
                )
            )}
            </div>
        </>
    )
}
